
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals.js';
import { HelmetProvider } from 'react-helmet-async';

const LazyAppNew = lazy(() => import('./components/AppNew.js'));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <Router>
      <Suspense fallback={<div style={{ backgroundColor: 'rgba(22, 105, 180, 0.8)', height: '100vh', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</div>}>
        <LazyAppNew />
      </Suspense>
    </Router>
  </HelmetProvider>
);

reportWebVitals();
